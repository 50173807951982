body {
  background: url("../../images/bg.jpg");
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 700;
}

hr {
  border-color: $border-grey;

  &.medium {
    margin: 0.625rem 0;
  }
}

/* Grid */
.row {
  @include media-breakpoint-down(lg) {
    margin-left: -0.625rem;
    margin-right: -0.625rem;
  }
}

.container {
  @include media-breakpoint-down(lg) {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
}

.col,
[class*="col-"] {
  @include media-breakpoint-down(lg) {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
}

.small-gutters {
  margin-right: -0.25rem;
  margin-left: -0.25rem;

  > .col,
  > [class*="col-"] {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  }
}

.medium-gutters {
  margin-right: -0.5rem;
  margin-left: -0.5rem;

  > .col,
  > [class*="col-"] {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}

.large-gutters {
  margin-right: -1rem;
  margin-left: -1rem;

  > .col,
  > [class*="col-"] {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

/* Main */
#site-wrapper {
  .container {
    background: #fff;
  }
}

/* Alerts */
.alert {
  ul {
    margin: 0;
  }
}

.alert-info {
  background: $info;
  border-color: $info;
  color: #fff;
}

.alert-danger {
  background: $danger;
  border-color: $danger;
  color: #fff;
}

.alert-success {
  background: $success;
  border-color: $success;
  color: #fff;
}

.alert-warning {
  background: $warning;
  border-color: $warning;
  color: #fff;
}

/* Cookies */
#site-cookies {
  .site-cookies-current-setting {
    display: block;
    font-size: 0.75rem;
  }
}

#cookie-deny {
  @include media-breakpoint-down(lg) {
    margin-bottom: 0.5rem;
  }
}

/* Ads */
.ads-title {
  font-size: 0.75rem;
  color: grey;
}

#ads-supertoppbanner {
  div {
    display: flex;
  }
}

#ads-toppbanner {
  @include media-breakpoint-down(md) {
    margin-top: -0.75rem;
  }

  div {
    display: flex;
  }

  .ads-title {
    @include media-breakpoint-up(lg) {
      position: absolute;
      bottom: 0.3125rem;
      left: -3.6rem;
    }

    @include media-breakpoint-up(xl) {
      bottom: 0.5rem;
      left: -4.25rem;
    }
  }
}

#ads-mellanbanner {
  div {
    display: flex;
    width: 100%;
  }

  img {
    width: 100%;
  }
}

#ads-nyhetsbanner {
  margin: 0.5rem 0 1rem;

  div {
    display: flex;
    width: 100%;
  }

  img {
    width: 100%;
  }
}

#ads-annonstavla-first {
  img {
    width: 100%;
    margin-bottom: 0.5rem;
  }
}

#ads-annonstavla {
  img {
    width: 100%;
    margin-bottom: 0.5rem;
  }
}

#ads-garantiblock {
  img {
    width: 100%;
    margin-bottom: 0.5rem;
  }
}

#ads-block {
  img {
    width: 100%;
    margin-bottom: 0.5rem;
  }
}

/* Modals */
.modal-content {
  border: 0;
  border-radius: 0.25rem;
}

.modal-header {
  background: $primary;
  color: #fff;
  border: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);

  .close {
    color: #fff;
    text-shadow: none;
    opacity: 1;
    padding: 1.25rem;
    margin-top: -1.125rem;
  }
}

.modal-backdrop {
  transition: none !important;
}

/* Navs */
.sidebar-tabs {
  border-bottom: 0;

  .nav-link {
    border-radius: 0;
  }
}

/* List groups */
.list-group {
  border-radius: 0;

  .list-group-item-anchor {
    padding: 0;

    a {
      display: block;
      width: 100%;
      padding: 0.5rem 1rem;

      &:hover,
      &:focus {
        color: $red;
        text-decoration: none;
      }
    }
  }
}

/* Tables */
.table-no-thead {
  tr {
    &:first-child {
      th,
      td {
        border-top: 0;
      }
    }
  }
}

/* ACF Forms */
.site-content {
  .af-form {
    .acf-field,
    .af-submit {
      margin: 0 0 0.5rem 0;
      padding: 0;
      border: 0;
    }

    .acf-input-wrap {
      input {
        @extend .form-control;
      }
    }

    .acf-input {
      textarea,
      select {
        @extend .form-control;
      }
    }

    .select2-container.-acf .select2-selection,
    .select2-container--default.select2-container--focus .select2-selection--multiple {
      border-color: #ced4da;
    }

    .acf-url {
      i {
        top: 11px;
        left: 5px;
      }
    }

    .af-submit-button {
      @extend .btn;
      @extend .btn-primary;
    }
  }
}

/* Swal */
.swal2-popup {
  border-radius: 0.25rem !important;
}

.swal2-icon.swal2-success .swal2-success-ring {
  border-color: $green !important;
}

.swal2-icon.swal2-success [class^=swal2-success-line] {
  background-color: $green !important;
}

.swal2-icon.swal2-error {
  border-color: $red !important;
  color: $red !important;
}

.swal2-icon.swal2-error [class^=swal2-x-mark-line] {
  background-color: $red !important;
}

.swal2-icon.swal2-warning {
  border-color: $orange !important;
  color: $orange !important;
}

.swal2-icon.swal2-info {
  border-color: $cyan !important;
  color: $cyan !important;
}

.swal2-icon.swal2-question {
  border-color: #595959 !important;
  color: #595959 !important;
}

.swal2-confirm {
  @extend .btn;
  @extend .btn-success;

  &:focus {
    background: $green;
    border-color: $green;
  }
}

.swal2-deny,
.swal2-cancel {
  @extend .btn;
  @extend .btn-danger;

  margin-left: 0.25rem;
}

.swal2-backdrop-show {
  background: rgba(0, 0, 0, 0.8) !important;
}

@media print {
  @page {
    size: auto;
    margin: 16mm 8mm 16mm 8mm;
  }

  body {
    background: #fff !important;
    color: #000 !important;
    margin: 0 !important;
    font-size: 14pt !important;
  }

  .site-header-logo {
    max-width: 100mm !important;
  }

  .entry-title {
    font-size: 28pt !important;
  }

  .entry-meta {
    font-size: 14pt !important;
    margin-bottom: 4mm !important;
  }

  .entry-content img,
  .post-thumbnail {
    width: 100% !important;
  }

  #ads-supertoppbanner,
  #ads-toppbanner,
  #ads-nyhetsbanner,
  .navbar-toggler,
  .site-header-info-bar,
  hr,
  .share-post,
  .sidebar-inner,
  .sidebar-outer,
  #site-footer {
    display: none !important;
  }
}
