a,
button,
.btn {
  &:hover,
  &:focus {
    outline: 0;
    box-shadow: none;
  }
}

.btn {
  padding: 0.5rem 1.25rem;
}

#login-username,
#login-password,
.comment-form input[type=email],
.comment-form input[type=text],
.comment-form input[type=url],
.comment-form textarea,
.form-control,
.search-form .search-field {
  height: auto;
  padding: 0.5rem;
}

.pagination {
  .nav-links {
    width: 100%;
    display: block;
    text-align: center;
    position: relative;
    margin-bottom: 1rem;

    @include media-breakpoint-up(lg) {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
    }

    .prev {
      @extend .btn;
      @extend .btn-primary;

      @include media-breakpoint-down(md) {
        display: block;
        margin-bottom: 0.5rem;
      }

      @include media-breakpoint-up(lg) {
        position: absolute;
        left: 0;
      }
    }

    .next {
      @extend .btn;
      @extend .btn-primary;

      @include media-breakpoint-down(md) {
        display: block;
        margin-top: 0.5rem;
      }

      @include media-breakpoint-up(lg) {
        position: absolute;
        right: 0;
      }
    }

    .current {
      font-weight: 700;
      text-decoration: underline;
    }
  }
}
