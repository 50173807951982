/** Import Bootstrap functions */
@import "~bootstrap/scss/functions";

$slick-loader-path: "~slick-carousel/slick/";
$slick-font-path: "#{$slick-loader-path}fonts/";

$title-font-family: "Roboto Condensed", sans-serif;
$title-font-weight: 700;
$text-font-family: "Roboto", sans-serif;
$text-font-weight: 400;

$white: #fff;
$black: #000;
$text-dark-grey: #292221;
$text-grey: #333232;
$light-grey: #f2f0f0;
$border-grey: #e5e3e3;
$border-dark-grey: #403f3f;
$red: #dd3c27;
$green: #19ab0e;
$orange: #de7933;
$teal: #12d0de;

$primary: $red;

$theme-colors: (
  primary: $red,
  light: $light-grey
);

$link-color: $text-grey;
$link-hover-color: $red;

$grid-gutter-width: 2.5rem;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1280px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1280px
);

$border-radius: 0.25rem;

$transition-collapse: all 0 ease;

$border-color: $border-grey;

$nav-tabs-border-color: $border-grey;
$pagination-border-color: $border-grey;

$modal-content-border-radius: 0.25rem;
$modal-content-inner-border-radius: 0.25rem;
$modal-backdrop-opacity: 0.8;

/** Bootstrap navbar fix (https://git.io/fADqW) */
$navbar-dark-toggler-icon-bg: none;
$navbar-light-toggler-icon-bg: none;
