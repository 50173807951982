.site-header-info-bar {
  font-size: 0.875rem;
  padding: 0.25rem 0;
  min-height: 2rem;

  a {
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}

.site-header-date {
  img {
    vertical-align: top;
  }
}

.site-header-weather {
  @include media-breakpoint-up(xl) {
    min-width: 128px;
    text-align: center;
  }
}

.site-header-social-media {
  text-align: right;

  svg {
    font-size: 1.5rem;
  }
}

.site-header-social-media-mobile {
  background: $white;
  padding: 0.9375rem 0 0.3125rem 0;

  svg {
    font-size: 2.25rem;
  }
}

.site-header-search-toggle {
  @include media-breakpoint-up(xl) {
    margin-top: 2px;
  }

  svg {
    font-size: 1.25rem;
  }
}

.site-header-search-toggle-mobile {
  margin: 0.25rem 0 0 0.5rem;

  svg {
    font-size: 1.7rem;
  }
}

#search-box {
  .fa-search {
    font-size: inherit;
  }

  .modal-body {
    text-align: left;
  }
}

.site-header-logo-col {
  @include media-breakpoint-down(lg) {
    padding-right: 0 !important;
  }
}

.site-header-navbar-toggler-col {
  padding-bottom: 6.565%;
}

.navbar-toggler {
  padding: 0;
  font-size: inherit;
  border: 0;
  border-radius: 0;
  touch-action: manipulation;
  color: #000;

  svg {
    font-size: 2.25rem;
  }
}

.post-type-archive,
.single-planket {
  #primary-navigation {
    .nav-item-insant,
    .nav-item-planket {
      .nav-link {
        background: $border-grey;
      }

      .dropdown-item-planket {
        font-weight: 700;
      }
    }
  }
}

#primary-navigation {
  background: $light-grey;
  margin: 0.5rem 0;
  transition: height 250ms ease-in;
  will-change: height;

  .nav-item {
    .nav-link {
      padding: 0.625rem 0.75rem 0.5rem;
      border-top: 1px solid $border-grey;
      border-left: 1px solid $border-grey;

      @include media-breakpoint-up(xl) {
        border-bottom: 3px solid $border-grey;
      }

      &:hover {
        background: $red;
        color: $white;
        border-color: $red;
      }
    }

    &.nav-item-mitt-konto {
      .nav-link {
        background: $red !important;
        color: $white !important;
        border-color: $red !important;
      }
    }

    &.active,
    &.current-menu-ancestor {
      .nav-link {
        background: $border-grey;

        &:hover {
          background: $red;
          color: $white;
          border-color: $red;
        }
      }

      .dropdown-item {
        &.active {
          font-weight: 700;
        }
      }
    }

    .dropdown-toggle {
      &::after {
        display: none;
      }
    }

    &.show {
      &.dropdown {
        .dropdown-toggle {
          background: $red;
          color: $white;
          border-color: $red;
        }

        .dropdown-menu {
          background: $red;
          margin: 0;
          padding: 0;
          border: 0;
          border-radius: 0;
          text-align: center;
          min-width: 100%;

          @include media-breakpoint-down(lg) {
            position: relative !important;
            transform: none !important;
          }

          .dropdown-item {
            color: $white;
            padding: 0.5rem 0.75rem;
            border-top: 1px solid rgba(255, 255, 255, 0.1);

            &:hover {
              background: $border-grey;
              color: $text-grey;
            }
          }
        }
      }
    }
  }
}
