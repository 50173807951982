/* Front */
.front-page-news-slider {
  visibility: hidden;
  margin-bottom: 2.375rem !important;

  &.slick-initialized {
    visibility: visible;
  }

  .slick-slide {
    div {
      &:first-child {
        display: flex;
      }
    }
  }

  .front-page-news-slider-inner {
    position: relative;

    a {
      &:hover,
      &:focus {
        color: $text-grey;
      }
    }

    img {
      width: 100%;
    }
  }

  .front-page-news-slider-caption {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    padding: 0.25rem;
    background: rgba(255, 255, 255, 0.6);
    text-align: center;

    @include media-breakpoint-up(lg) {
      padding: 0.25rem 0.5rem;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.85);
    }
  }

  .front-page-news-slider-caption-text {
    font-weight: 700;
    font-size: 26px;
    line-height: 26px;
    white-space: nowrap;
  }

  .slick-arrow {
    top: auto;
    width: 2.5rem;
    height: 2.5rem;
    z-index: 999;
    bottom: 0;
    transform: none;

    &::before {
      font-size: 2.5rem;
      opacity: 0.6;
    }

    &:hover {
      &::before {
        opacity: 0.85;
      }
    }
  }

  .slick-prev {
    left: 0.25rem;
  }

  .slick-next {
    right: 0.25rem;
  }

  .slick-dots {
    bottom: -1.5rem;

    li {
      width: 1rem;
      height: 1rem;
      margin: 0;

      button {
        width: 1rem;
        height: 1rem;

        &::before {
          width: 1rem;
          height: 1rem;
          font-size: 0.75rem;
          line-height: 1rem;
        }
      }
    }
  }
}

.front-page-news-first-inner {
  margin-bottom: 0.5rem;

  @include media-breakpoint-up(lg) {
    margin-bottom: 1rem;
  }

  a {
    &:hover {
      color: $text-grey;
      text-decoration: none;
    }
  }

  .front-page-news-first-thumbnail {
    float: left;
    margin-right: 0.5rem;
    border: 1px solid $border-dark-grey;
    max-width: 96px;
  }

  .front-page-news-first-badge {
    background: $border-grey;
    color: $text-grey;
    margin-right: 0.4375rem;
  }

  .front-page-news-first-title {
    font-size: 0.875rem;
    margin: 0.25rem 0;
  }

  .front-page-news-first-date {
    font-size: 0.75rem;
  }

  .front-page-news-first-preamble {
    font-size: 0.75rem;
  }
}

#ads-mellanbanner {
  margin-bottom: 2.0625rem;

  @include media-breakpoint-up(lg) {
    margin-bottom: 2.5rem;
  }
}

.front-page-news-second-inner {
  margin-bottom: 0.5rem;

  @include media-breakpoint-up(lg) {
    margin-bottom: 1rem;
  }

  a {
    &:hover {
      color: $text-grey;
      text-decoration: none;
    }
  }

  .front-page-news-second-badge {
    border-top: 0.25rem solid $border-grey;
    display: block;
    width: 100%;
    margin-bottom: 0.5rem;
  }

  .front-page-news-second-title {
    font-size: 0.875rem;
    margin: 0;
  }

  .front-page-news-second-date {
    font-size: 0.75rem;
    margin-bottom: 0.125rem;
    display: inline-block;
  }

  .front-page-news-second-preamble {
    font-size: 0.75rem;
  }
}

/* Lunch */
.font-size-normal {
  font-size: 1rem;
}

.text-bold {
  font-weight: 700;
}

.text-sm {
  font-size: 0.875rem;
}

.lunchRestaurant {
  margin-bottom: 1rem;
  border-bottom: 1px solid $border-grey;
}

.lunch-restaurant-inner {
  background: #f5f5f5;
  padding: 1rem 1rem 0.75rem 1rem;
  border: 1px solid $border-grey;
}

.lunch-restaurant-menu-inner {
  padding-top: 1rem;

  @include media-breakpoint-up(lg) {
    padding-top: 0;
    padding-left: 1rem;
  }
}
