#companies-slider {
  border-bottom: 1px solid $border-grey;
  margin-bottom: 0.4375rem;
  padding-bottom: 1rem;
  height: 5.4375rem;

  div {
    :first-of-type {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  img {
    max-height: 4.375rem;
    margin: 0 auto;
  }
}
