.single {
  h1 {
    font-size: 1.5rem;
    letter-spacing: -1px;
    margin-bottom: 0.25rem;

    @include media-breakpoint-up(xl) {
      font-size: 2.1875rem;
    }
  }

  .entry-meta {
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
  }

  .entry-content {
    a {
      color: $red;
      font-weight: 700;

      &:hover,
      &:focus {
        color: $text-grey;
        text-decoration: underline;
      }

      &.ads-title {
        color: grey;
        font-weight: normal;

        &:hover,
        &:focus {
          color: $red;
        }
      }
    }
  }

  .wp-caption {
    background: $light-grey;
    padding: 0.5rem;

    .wp-caption-text {
      color: $text-grey;
    }
  }
}

#premium-upsale {
  position: relative;
  margin: 1rem 0;
  background: $light-grey;
  padding: 2rem;
  border-top: 0.375rem solid $red;

  @include media-breakpoint-up(xl) {
    margin: 2rem 0;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 12rem;
    top: -12.25rem;
    left: 0;
    background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), #fff);
  }

  .card-title {
    margin: 0;
  }

  .premium-upsale-register {
    border-bottom: 1px solid $border-grey;
    margin-bottom: 0.625rem;

    @include media-breakpoint-up(lg) {
      border-bottom: none;
      margin-bottom: 0;
      border-right: 1px solid $border-grey;
    }
  }

  .login-remember {
    display: none !important;
  }
}

#login-username,
#login-password {
  @extend .form-control;
}

#login-form-submit {
  @extend .btn;
  @extend .btn-primary;
}

.blog,
.archive {
  article {
    &.post,
    &.planket {
      margin-bottom: 1rem;
      border-bottom: 1px solid $border-grey;
    }
  }
}

.posts-navigation {
  .nav-links {
    display: flex;
    justify-content: space-between;
  }
}
