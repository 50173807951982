#site-footer {
  .container {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  h3 {
    font-size: 1rem;
  }
}
